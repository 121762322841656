<app-header>
  <bit-search [placeholder]="'search' | i18n" [formControl]="searchControl"></bit-search>
  <a type="button" bitButton *ngIf="isProviderAdmin" buttonType="primary" (click)="createClient()">
    <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
    {{ "addNewOrganization" | i18n }}
  </a>
</app-header>

<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="tw-sr-only">{{ "loading" | i18n }}</span>
</ng-container>

<ng-container *ngIf="!loading">
  <bit-table-scroll [dataSource]="dataSource" [rowSize]="53" class="table table-hover table-list">
    <ng-container header>
      <th colspan="2" bitCell bitSortable="organizationName" default>{{ "client" | i18n }}</th>
      <th bitCell bitSortable="seats">{{ "assigned" | i18n }}</th>
      <th bitCell bitSortable="occupiedSeats">{{ "used" | i18n }}</th>
      <th bitCell bitSortable="remainingSeats">{{ "remaining" | i18n }}</th>
      <th bitCell bitSortable="plan">{{ "billingPlan" | i18n }}</th>
      <th></th>
    </ng-container>
    <ng-template bitRowDef let-row>
      <td bitCell width="30">
        <bit-avatar [text]="row.organizationName" [id]="row.id" size="small"></bit-avatar>
      </td>
      <td bitCell>
        <div class="tw-flex tw-items-center tw-gap-4 tw-break-all">
          <a bitLink [routerLink]="['/organizations', row.organizationId]">{{
            row.organizationName
          }}</a>
        </div>
      </td>
      <td bitCell class="tw-whitespace-nowrap">
        <span class="tw-text-muted">{{ row.seats }}</span>
      </td>
      <td bitCell class="tw-whitespace-nowrap">
        <span class="tw-text-muted">{{ row.occupiedSeats }}</span>
      </td>
      <td bitCell class="tw-whitespace-nowrap">
        <span class="tw-text-muted">{{ row.remainingSeats }}</span>
      </td>
      <td bitCell class="tw-whitespace-nowrap">
        <span class="tw-text-muted">{{ row.plan }}</span>
      </td>
      <td bitCell class="tw-text-right">
        <button
          [bitMenuTriggerFor]="rowMenu"
          type="button"
          bitIconButton="bwi-ellipsis-v"
          size="small"
          appA11yTitle="{{ 'options' | i18n }}"
        ></button>
        <bit-menu #rowMenu>
          <button type="button" bitMenuItem (click)="manageClientName(row)">
            <i aria-hidden="true" class="bwi bwi-pencil-square"></i>
            {{ "updateName" | i18n }}
          </button>
          <button type="button" bitMenuItem (click)="manageClientSubscription(row)">
            <i aria-hidden="true" class="bwi bwi-family"></i>
            {{ "manageSubscription" | i18n }}
          </button>
          <button *ngIf="isProviderAdmin" type="button" bitMenuItem (click)="remove(row)">
            <span class="tw-text-danger">
              <i aria-hidden="true" class="bwi bwi-close"></i> {{ "unlinkOrganization" | i18n }}
            </span>
          </button>
        </bit-menu>
      </td>
    </ng-template>
  </bit-table-scroll>
  <div *ngIf="dataSource.data.length === 0" class="tw-mt-10">
    <app-no-clients
      [showAddOrganizationButton]="isProviderAdmin"
      (addNewOrganizationClicked)="createClient()"
    />
  </div>
</ng-container>
